<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <!-- <router-link :to="{name: 'admin-master' }" class="root">
               Librarian
            </router-link> -->
            <span class="child"> Librarian / Reserve Book </span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Reserve Book</v-card-title>

            <div class="add-section">
                <center>
                    <v-col lg="5">
                        <label style="font-weight:bold;color:blue">Select Member</label>
                        <v-autocomplete class="text" 
                        placeholder="Please Select User"
                        v-model="selecteduser" 
                        :items="empmemberlist" 
                        item-text="username"
                        item-value="id"
                         outlined  dense :rules="[rules.required]" 
                        clearable required @change="findUserInfo(selecteduser)"></v-autocomplete>
                    </v-col>
                    
                    
                    <!-- <label v-if="bookLimit" style="font-weight:bold;color:red">Maximum Book limit exeed!!</label> -->
                </center>
           


            </div>

                 <div v-if="userInfo" class="cus-card">
                    <v-row>
                        <v-col lg="2"> <label class="c-label">PRN</label></v-col>
                        <v-col><span>: {{empmemberinfo.employee_code}}</span></v-col>
                        <v-col lg="2"> <label class="c-label">Name</label></v-col>
                        <v-col><span>: {{empmemberinfo.empname}}</span></v-col>
                       
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">User Type</label></v-col>
                        <v-col><span>: {{empmemberinfo.usertype}}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Date of Membership</label></v-col>
                        <v-col><span>: {{empmemberinfo.date_of_membership}}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Contact</label></v-col>
                        <v-col><span>: {{empmemberinfo.mobile_number}}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Email</label></v-col>
                        <v-col><span>: {{empmemberinfo.email}}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Address</label></v-col>
                        <v-col><span>: {{empmemberinfo.address}}</span></v-col>
                        <v-col lg="3"> <label class="c-label">City</label></v-col>
                        <v-col><span>: {{empmemberinfo.city}}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">State</label></v-col>
                        <v-col><span>: {{empmemberinfo.state}}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Country</label></v-col>
                        <v-col><span>: {{empmemberinfo.country}}</span></v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="2"> <label class="c-label">Pin</label></v-col>
                        <v-col><span>: {{empmemberinfo.pin}}</span></v-col>
                        <v-col lg="3"> <label class="c-label">Total Checked-Out Book</label></v-col>
                        <v-col><span>: {{empmemberinfo.total_checked_out_book}}</span></v-col>
                    </v-row>
                </div>
            <v-card-text class="pt-0"  v-if="userInfo" >
   <center v-if="limit">
    <p class="blink_me">Reservation Book limit Exeeds!!!</p>
</center>      
            <v-data-table  :item-class= "row_classes"  v-model="selected" :headers="headers" :items="bookList1" item-key="name" class="elevation-1 mt-5" :search="search" @current-items="getFiltered">
                <v-spacer></v-spacer>
                <template v-slot:top>
                    <v-toolbar flat color="white">
                        <v-spacer></v-spacer>
                        <v-text-field v-model="search"  label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field>
                        <download-excel class="ml-5" :data="filterArr" worksheet="bookItemlist" name="BookItem.xls">
                            <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn>
                        </download-excel>
                    </v-toolbar>

                    <tr class="t-row">
                        <div>
                            <th class="d-flex">
                                <span v-for="header in headers" :key="header.text">
                                <span v-if="filters.hasOwnProperty(header.value)">
                                        <v-autocomplete class="m-2" flat hide-details small dense outlined :label="header.text" multiple clearable :items="columnValueList(header.value)" v-model="filters[header.value]"></v-autocomplete>
                                    </span>
                                </span>
                            <!-- <v-autocomplete class="m-2" @change="searchByAuthor" flat hide-details small dense outlined label="Authors" multiple clearable :items="authorsList" v-model="selected_author" item-text="name" item-value="id"></v-autocomplete> -->
                            </th>
                        </div>
                    </tr>
                </template>
                <template v-slot:item.no_copies="{ item }">
                        <router-link class="bookItem" :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                           {{item.copies}}
                        </router-link>
                    </template>
                    <!-- <template v-slot:item.reserve_book="{ item }"> -->
                    <template v-slot:item.reserve_book="{ item }">
                 <v-checkbox v-model="reserve_book"  :disabled="item.isReserve || limit " @click="checkLimit"   :value="item.id"></v-checkbox>
                    </template>
                    <template v-slot:item.no_available_copies="{ item }">
                        <router-link  class="bookItem"  :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                           {{item.available}}
                        </router-link>
                    </template>
                <template v-slot:item.authors="{ item }">
                    <span v-for="author in item.authors" :key="author">{{author}}<br /></span>
                </template>
            </v-data-table>
            <center >
                    <v-btn v-if="reserve_book.length !== 0 && userInfo" class="mt-4" color="primary" @click="reserveBook">
                        Reserve Book
                    </v-btn></center>
            </v-card-text>

        </v-card>
         <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            userid: "",
            bookitemid: "",
            errorMsg: {
                roleLinkId: false,
            },
            rules: {
                required: (value) => !!value || "Required.",
            },
            snackbar_msg: "",
            reserve_book:[],
            bookLimit:false,
            color: "",
            snackbar: false,
            empmemberlist: [],
            empmemberinfo: [],
            bookinformation: [],
            empmemberbooklendinginfo: [],
            allBookInfo: [],
            selecteduser: "",
            selectedbookitem: "",
            selected:null,
            org: "",
            inSave: true,
            dialog: false,
            limit:false,
            headers: [
                {
                    text: "Sr No",
                    value: "srno",
                },
                { text: "ISBN", value: "isbn" },
                { text: "Title", value: "title" },
                { text: "Reservation Status", value: "reservation_status"},
                { text: "Reserve Book", value: "reserve_book" },
                { text: "Edition", value: "edition" },
                //   { text: 'Book Format', value: 'bookFormat' },
                { text: "Book Type", value: "bookType" },
                { text: "Authors", value: "authors" },
                { text: "Category", value: "category" },
                { text: "Publisher", value: "publisher" },
                { text: "No of Copies", value: "no_copies" },
                { text: "Available Copies", value: "no_available_copies" },
            ],
            search: "",
            filters: {
                isbn: [],
                title: [],
                category: [],
                publisher: [],
                authors: [],
            },            
            bookList: [],
            filterArr: [],
            bookCategoryList: [],
            roleLink_list: [],
            role_list: [],
            userInfo: false,
            bookInfo: false,
            alreadyReserved:null,
            reservationLimit:null,
            total_reserveChecked:null,
        }),

             computed: {
            bookList1() {
                return this.bookList.filter((d) => {
                    return Object.keys(this.filters).every((f) => {
                        return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
                    });
                });
            },
        },
        mounted() {
            this.onLoad();
            // this.selecteduser=localStorage.getItem("EPS-uid");
        //    this.findUserInfo(this.selecteduser)
        //    this.search=this.$route.params.assno;
          
        },
        methods: {
checkLimit(){
this.total_reserveChecked = this.alreadyReserved
this.total_reserveChecked = this.total_reserveChecked + this.reserve_book.length

if( this.total_reserveChecked > this.reservationLimit){
  this.reserve_book.pop();
  this.showSnackbar("#b71c1c", "Book Reservation limit exeeds!!");
}
},

                row_classes(item) {
        if (item.isReserve) {
          return "yellow"; //can also return multiple classes e.g ["orange","disabled"]
        }
     } ,
            reserveBook(){
                this.overlay = true;
                
                const data={
                    bookIds:this.reserve_book,
                     memberId :this.selecteduser    
                   //memberId :this.empmemberlist.indexOf(this.selecteduser)
               }
               
        axios
                    .post("/ReserveBook/reserveBook",data)
                    .then((res) => {
                        console.log('res--',res);
                        if (res.data.msg == "200") {
                            this.overlay = false;
                           this.showSnackbar("#4caf50", "Book reserved Successfully!!");
                           this.onLoad()
                            this.getReserveList();

                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false;                    
                    });
            },
               
            onlyUnique(value, index, self) {
                if(value != null && value != "" && value)
                    return self.indexOf(value) === index;
            },

            columnValueList(val) {
                var data = this.bookList.map((d) => d[val]);
                data = data.filter(this.onlyUnique);
                return data
            },

            getFiltered(e) {
                console.log(e);
                this.filterArr = e;
            },
            findUserInfo(seluser) {
               var  params ={"userid":seluser}
                console.log(seluser);
                this.overlay = true;
                axios
                    .post("/ReserveBook/getEmpMemInformation", params)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.userInfo = true;
                            this.empmemberinfo = res.data.memberinfo;
                            this.reserve_book = []
                            // this.empmemberbooklendinginfo = res.data.booklendinginformation;
                            this.bookList = res.data.bookList;
                            this.limit = res.data.isReservation_limit_exeed
                            this.alreadyReserved = res.data.reserved_count
                            this.reservationLimit =res.data.Reservation_limit
                //     if(this.empmemberinfo.max_number_book_allowed <= this.empmemberbooklendinginfo.length){
                //     this.bookLimit = true
                // }
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false
                    });
            },


            getReserveList() {
                this.overlay = true;
                var  params ={"userid":this.selecteduser}
                axios
                    .post("/ReserveBook/getReserveList",params)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            // this.empmemberlist = res.data.userlist;
                            this.bookList = res.data.bookList;
                            this.limit = res.data.isReservation_limit_exeed
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },


            onLoad() {               
                let singleBook = this.$route.params.singleBook
                this.overlay = true;
                // if(singleBook){                    
                    
                //     axios
                //     .post("/ReserveBook/fetchSingleBookDetails",singleBook)
                //     .then((res) => {
                //         if (res.data.msg == "200") {
                //             this.overlay = false;
                //             this.empmemberlist = res.data.userlist;
                //             this.reserve_book = [];
                           
                //         }
                //     })
                //     .catch((error) => {
                //         this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                //         window.console.log(error);
                //     })
                //     .finally(() => {
                //         // var overlay = false;
                //     });
                // }else{  
                    //window.alert("In Else")                  
                    axios
                    .post("/ReserveBook/reserveBookInit")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.empmemberlist = res.data.userlist;
                            this.reserve_book = [];                           
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
                // }             
            
                
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
.add-section {
    display: block;
    margin: 1rem;
}
    .bookItem{
        text-decoration: underline !important;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
    .elevation-1 /deep/ tr {
        white-space: nowrap !important;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 1rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
        .blink_me {
        white-space: nowrap;
        color: red;
        animation: blinker 1s linear infinite;
        margin: 1rem;
        font-weight: bold;
        font-size: 20px;
    }
        @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
</style>
